import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { concatMap, from, lastValueFrom, map, of, retry, Subject, tap, timer } from 'rxjs';
import { ChangeType as MSGraphChangeType } from '@microsoft/microsoft-graph-types';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

import { AuthService } from './auth.service';
import { CacheService } from './cache.service';
import { CalendarService } from './calendar.service';
import { ChatService } from './chat.service';
import { GlobalSpinnerService } from './global-spinner.service';
import { LogService } from './log.service';
import { TeamService } from './team.service';
import { UserService } from './user.service';

import { CollaborationToolBase } from '../models/components/collaboration-tool.model';
import { Call, CallContext } from '../models/services/call.model';
import { SignalRTeamInfoData } from '../models/services/signalr.model';
import { TeamSettings } from '../models/services/team.model';

import { reloadApp } from '../utils/network.util';
import { cachedTeamSettingsAreOutdated, mapSignalRTeamInfoDataToTeamInfoData } from '../utils/services/team.util';
import { resolveUserBaseDataUpdate } from '../utils/services/user.util';

import { environment } from 'src/environments/environment';

const LOGGING_CONTEXT = 'SignalRService';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  private hubConnection?: HubConnection;

  private _teamInfoDataUpdated = new Subject<SignalRTeamInfoData>();
  private _callCreatedOrUpdated = new Subject<Call>();
  private _callDeleted = new Subject<[string, CallContext]>();
  private _collaborationToolCreatedOrUpdated = new Subject<CollaborationToolBase>();
  private _teamSettingsUpdated = new Subject<TeamSettings>();

  public teamInfoDataUpdated = this._teamInfoDataUpdated.asObservable().pipe(
    concatMap((signalRTeamInfoData) =>
      this.cacheService.getGeneralData().pipe(
        concatMap(({ teamSettings }) => {
          if (cachedTeamSettingsAreOutdated(signalRTeamInfoData, teamSettings)) {
            return this.teamService.getTeamInfoData();
          }

          return of(mapSignalRTeamInfoDataToTeamInfoData(signalRTeamInfoData, teamSettings));
        }),
      ),
    ),
  );

  public callCreatedOrUpdated = this._callCreatedOrUpdated.asObservable();
  public callDeleted = this._callDeleted.asObservable();
  public collaborationToolCreatedOrUpdated = this._collaborationToolCreatedOrUpdated.asObservable();
  public teamSettingsUpdated = this._teamSettingsUpdated.asObservable().pipe(
    tap((teamSettings) => {
      const teamInfoData = this.teamService.teamInfoData$.value;
      if (teamInfoData?.myTeam) {
        teamInfoData.myTeam.settings = teamSettings;
        this.teamService.teamInfoData$.next(teamInfoData);
      }
    }),
    concatMap((teamSettings) =>
      this.cacheService
        .updateGeneralDataFn(({ teamSettings: cachedTeamSettings }) => {
          const myTeamObjectId = this.teamService.teamInfoData$.value?.myTeam?.objectId;
          if (myTeamObjectId) cachedTeamSettings.set(myTeamObjectId, teamSettings);

          return { teamSettings: cachedTeamSettings };
        })
        .pipe(map(() => teamSettings)),
    ),
  );

  public constructor(
    private readonly cacheService: CacheService,
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly globalSpinnerService: GlobalSpinnerService,
    private readonly teamService: TeamService,
    private readonly http: HttpClient,
    private readonly chatService: ChatService,
    private readonly logService: LogService,
    private readonly calendarService: CalendarService,
  ) {}

  public initializeConnection(): void {
    if (this.hubConnection) return;

    this.hubConnection = new HubConnectionBuilder()
      .configureLogging(environment.enableLogging ? signalR.LogLevel.Debug : signalR.LogLevel.Warning)
      .withUrl(`${environment.backendUrl}/api/hub/client-notifications`, {
        accessTokenFactory: () =>
          lastValueFrom(
            this.authService.getCachedTokensOrAuthenticate('Backend', true).pipe(map((token) => token.accessToken)),
          ),
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext: { elapsedMilliseconds: number }) => {
          if (retryContext.elapsedMilliseconds < 10000) return 2_500;
          if (retryContext.elapsedMilliseconds < 30000) return 10_000;
          if (retryContext.elapsedMilliseconds < 60000) return 15_000;

          return 30000;
        },
      })
      .build();

    this.hubConnection.on('UpdateTeamInfoData', (teamInfoData: SignalRTeamInfoData) => {
      this.logService.logDebug(LOGGING_CONTEXT, teamInfoData);

      if (teamInfoData.myTeam && this.userService.userInfo) {
        const me = teamInfoData.myTeam.users.find((u) => u.objectId === this.userService.userInfo!.user.objectId);

        if (me) resolveUserBaseDataUpdate(me, this.userService.userInfo.user);
      }

      this._teamInfoDataUpdated.next(teamInfoData);
    });

    this.hubConnection.on('CreateOrUpdateCall', (call: Call) => {
      this.logService.logDebug(LOGGING_CONTEXT, call);

      const me = call.participants.find((p) => p.userObjectId === this.userService.userInfo?.user.objectId);
      if (me && this.userService.userInfo) resolveUserBaseDataUpdate(me, this.userService.userInfo.user);

      this._callCreatedOrUpdated.next(call);
    });

    this.hubConnection.on('DeleteCall', (callIdentifier: string, context: CallContext) => {
      this.logService.logDebug(LOGGING_CONTEXT, `Deleted call ${callIdentifier}`);

      this._callDeleted.next([callIdentifier, context]);
    });

    this.hubConnection.on('CreateOrUpdateCollaborationTool', (collaborationTool: CollaborationToolBase) => {
      this.logService.logDebug(LOGGING_CONTEXT, collaborationTool);

      this._collaborationToolCreatedOrUpdated.next(collaborationTool);
    });

    this.hubConnection.on('UpdateTeamSettings', (teamSettings: TeamSettings) => {
      this.logService.logDebug(LOGGING_CONTEXT, teamSettings);

      this._teamSettingsUpdated.next(teamSettings);
    });

    this.hubConnection.on('HandleChatMessageEvent', (id: string, changeType: MSGraphChangeType) => {
      this.logService.logDebug(LOGGING_CONTEXT, id, changeType);

      this.chatService.updateChat(id).subscribe();
    });

    this.hubConnection.on('HideOnlineMeeting', () => {
      this.calendarService.getEvents(true).subscribe();
    });

    // Delay showing the offline screen by 15 seconds to reduce flickering risk
    this.hubConnection.onreconnecting(() => {
      timer(15_000).subscribe(() => this.globalSpinnerService.showOfflineScreen());
    });

    this.hubConnection.onreconnected(() => {
      reloadApp(this.http, this.logService, this.cacheService, false);
    });

    // Retry starting SignalR over about a minute before reloading the app
    from(this.hubConnection.start())
      .pipe(retry(1))
      .subscribe({
        error: (err) => {
          this.globalSpinnerService.showOfflineScreen();

          throw err;
        },
      });
  }

  public disconnect(): void {
    if (this.hubConnection) {
      this.hubConnection.stop().catch((err) => {
        this.logService.logDebug(LOGGING_CONTEXT, 'SignalR Disconnect failed', err);

        throw err;
      });

      this.hubConnection = undefined;
    }
  }
}
